import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "g-row g-row--space_xs g-row--space_md-xl g-row--appearance_spaced" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "reception-widget__image-wrapper" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "reception-widget__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filter.values, (item) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["reception-widget", { 'is-active': item.value === $setup.model }]),
          onClick: _withModifiers(($event: any) => ($setup.select(item)), ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              class: "reception-widget__image",
              src: item.icon
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("span", _hoisted_6, _toDisplayString(item.title), 1)
        ], 10, _hoisted_3)
      ]))
    }), 256))
  ]))
}