export const tabsSimple = {
  mounted: (node: HTMLElement, params): any => {
    const items = [...node.getElementsByClassName('js-tabs-item')] as HTMLElement[]
    const contentItems = [...node.getElementsByClassName('js-tabs-content')] as HTMLElement[]

    const resetTabs = () => {
      items.forEach(item => {
        item.classList.remove('is-active')
      })
      contentItems.forEach(c => {
        c.classList.remove('is-active')
      })
    }

    const preselectTabByHash = () => {
      const { hash } = window.location
      const clearHash = hash ? hash.replace('#', '') : ''

      if (clearHash) {
        const section = contentItems.find(s => s.dataset.content === clearHash)
        const item = items.find(s => s.dataset.tab === clearHash)

        if (!section) return

        resetTabs()

        item.classList.add('is-active')
        section.classList.add('is-active')
      }
    }

    preselectTabByHash()

    const addHashHandler = () => {
      let hash = ''

      const activeItem = items.find(el => el.classList.contains('is-active'))
      const target = activeItem.dataset.tab

      if (target !== params.value?.initial) {
        hash = `#${target}`
      }

      const newPath = `${window.location.pathname}${hash}`

      window.history.replaceState(null, null, newPath)
    }

    items.forEach(el => {
      el.addEventListener('click', () => {
        resetTabs()

        const target = el.dataset.tab
        const section = contentItems.find(s => s.dataset.content === target)

        if (section) {
          section.classList.add('is-active')

          el.classList.add('is-active')

          if (params.value.initial) {
            addHashHandler()
          }
        }
      })
    })
  }
}
