import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "g-row" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--6-xs" }
const _hoisted_3 = { class: "g-cell g-cols g-cols--6-xs" }
const _hoisted_4 = { class: "ds-panel ds-panel--space_md" }
const _hoisted_5 = { class: "ds-panel__element ds-panel__element--offset_top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_vue_slider = _resolveComponent("vue-slider")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_d_control_input, {
          modelValue: $setup.model[0],
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => (($setup.model[0]) = $event)),
            $setup.debounceUpdate
          ],
          name: "min",
          type: "number",
          "input-label": $props.filter.title
        }, null, 8, ["modelValue", "input-label", "onUpdate:modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_d_control_input, {
          modelValue: $setup.model[1],
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => (($setup.model[1]) = $event)),
            $setup.debounceUpdate
          ],
          name: "max",
          type: "number"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_vue_slider, {
          modelValue: $setup.model,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.model) = $event)),
          transition: "0.2",
          clickable: false,
          "drag-on-click": true,
          min: $props.filter.values.min,
          max: $props.filter.values.max,
          height: 3,
          interval: $setup.interval,
          onDragEnd: $setup.update
        }, null, 8, ["modelValue", "min", "max", "interval"])
      ])
    ])
  ]))
}