
import { defineComponent } from 'vue'
import { useModalOpener, type Finish } from '@/composables/useModalOpener'

export default defineComponent({
  name: 'ModalTrigger',
  inheritAttrs: false,
  props: {
    component: {
      type: [String, Object],
      default: () => null,
    },
    name: {
      type: String,
    },
    classes: {
      type: Array,
    },
    clickToClose: {
      default: () => true,
    },
    onFinish: {
      type: Function,
      default: (arg) => {},
    },
  },
  setup(props, { slots, attrs }) {
    function handleOpen() {
      const { open } = useModalOpener({
        component: props.component,
        ...attrs,
        ...props,
      }, {
        ...attrs,
      }, props.onFinish as Finish)
      open()
    }

    return () =>
      slots.default({
        open: handleOpen,
      })
  },
})
