
import { App, defineAsyncComponent } from 'vue'

import LocalizationLanguageSwitcher from './LanguageSwitcher'

const LocalizationCountries = defineAsyncComponent(() => import('./Countries.vue'))
const CityChangeElement = defineAsyncComponent(() => import('./cities/Element.vue'))
const CityChangeForm = defineAsyncComponent(() => import('./cities/Form.vue'))

const install = (app: App<Element>, prefix = 'Localization'): void => {
  app
    .component(`${prefix}LanguageSwitcher`, LocalizationLanguageSwitcher)
    .component(`${prefix}Countries`, LocalizationCountries)
    .component(`${prefix}CityElement`, CityChangeElement)
    .component(`${prefix}CityForm`, CityChangeForm)
}

export default {
  install,
}
