

import { ref, computed, watch }  from 'vue'
import type { SetupContext }  from 'vue'

import multiproxy from '@aspectus/multiproxy'
import { extendComponent } from '@aspectus/vue-bem-classes'

import { makeLoading } from '@aspectus/vue-loading'
import { upload } from '../upload'
import type { UploadOptions, BlobWithName } from '../upload'

import ControlButton from './Button.vue'

interface Props {
  gui?: string,
  url?: string,
  uploadOptions: UploadOptions,
  progressShift: number,
  loadAfterAdd: boolean,
}

const [useClasses, Component]: any  = extendComponent('up-uploader', {
  emits: ['save', 'file', 'progress', 'error', 'update:loading'],
  props: {
    gui: { default: () => 'up-image' },
    url: { type: String, default: '' },
    uploadOptions: { type: Object, default: () => ({}) },
    // This used to display user that some amount of file already uploaded.
    // Even if it's not.
    progressShift: { type: Number, default: 0.01 },
    loadAfterAdd: { type: Boolean, default: true },
  },
  components: { ControlButton },
  setup(props: Props, {slots, emit, attrs }: SetupContext) {

    const proxiedProps = multiproxy(props)
    const classes = useClasses(proxiedProps)
    const progress = ref(0)
    const [state, load, loading] = makeLoading(0)
    const cancel = ref(() => ({}))

    const setProgress = (v: number) => {
      progress.value = v
      emit('progress', progress.value)
    }

    function onSave(result: BlobWithName | null) {
      emit('file', result)
      if (!result) return emit('save', null)
      if (!proxiedProps.loadAfterAdd) return 
      const uploader = upload({ url: proxiedProps.url, ...proxiedProps.uploadOptions }, result)
        .progress((e: ProgressEvent) => setProgress(e.loaded / e.total * (1 - proxiedProps.progressShift)))

      cancel.value = uploader.abort.bind(uploader)

      load(uploader.promise()
        .then((xhr: any) => {
          emit('save', xhr)
        })
        .catch((xhr: any) => {
          emit('error', xhr)
        })
        .finally(() => setProgress(0))
      )
    }

    watch([loading], (value, old) => {
      if (value !== old) {
        emit('update:loading', value)
      }
    })

    const guiAttrs = computed(() => ({ ...attrs, class: undefined, style: undefined }))

    return {
      classes,
      loading,
      onSave,
      cancel,
      progress,
      guiAttrs,
      slots,
      load,
      state,
    }
  }
}, [
  'gui',
  'overlay',
  'controls', 'control',
  'progress',
  'cancel',
])

export default Component

