import { createReceiver } from '@/plugins/resource/resource'
import { friendlyUrlGenerator } from '@/packages/vue-friendly'
import { baseResource } from '@aspectus/resource'
import { urlConstructor } from '@/plugins/resource/api'

// Models
const MODEL = 'doctors'

export const DOCTORS_CATALOG_URL = `/catalog/${MODEL}/`

export const DOCTORS_FILTERS_URL = urlConstructor(`doctor/catalog/filters/`, [])

// filter resources
export const doctorsCatalogResource = friendlyUrlGenerator([
  DOCTORS_CATALOG_URL, 'ajax'
], baseResource)

export const doctorsFiltersResource = createReceiver(DOCTORS_FILTERS_URL)
