import { App, defineAsyncComponent } from 'vue'

const ServicesCatalog = defineAsyncComponent(() => import('./ServicesCatalog.vue'))

const install = (app: App<Element>, prefix = 'Specialization'): void => {
  app
    .component(`${prefix}ServicesCatalog`, ServicesCatalog)
}

export default {
  install,
}
