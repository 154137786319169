import { App } from 'vue'

import Doctors from './Doctors.vue'
import Facilities from './Facilities.vue'

const install = (app: App<Element>, prefix = 'SearchFilter'): void => {
  app
    .component(`${prefix}Doctors`, Doctors)
    .component(`${prefix}Facilities`, Facilities)
}

export default {
  install,
}
