import { App } from 'vue'

import Toggler from './Toggler.vue'
import Counter from './Counter.vue'

const install = (app: App<Element>, prefix = 'Favorite'): void => {
  app
    .component(`${prefix}Toggler`, Toggler)
    .component(`${prefix}Counter`, Counter)
}

export default {
  install,
}
