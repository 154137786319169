import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "video-preview__wrapper" }
const _hoisted_3 = { class: "video-preview__image" }
const _hoisted_4 = {
  key: 0,
  class: "image-wrapper"
}
const _hoisted_5 = ["srcset"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "image-wrapper"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "video-preview__play" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_play_large = _resolveComponent("i-play-large")!
  const _component_ui_modal_trigger = _resolveComponent("ui-modal-trigger")!

  return (_openBlock(), _createBlock(_component_ui_modal_trigger, {
    component: "VideoModal",
    classes: ['modal--size_mac modal--with-decor'],
    url: $setup.props.url
  }, {
    default: _withCtx(({ open }) => [
      _createElementVNode("div", {
        class: "video-preview",
        onClick: _withModifiers(open, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            ($props.previewBase)
              ? (_openBlock(), _createElementBlock("picture", _hoisted_4, [
                  _createElementVNode("source", {
                    type: "image/webp",
                    srcset: $props.previewWebp
                  }, null, 8, _hoisted_5),
                  _createElementVNode("img", {
                    class: "image-wrapper__item image-wrapper__item--fit",
                    src: $props.previewBase
                  }, null, 8, _hoisted_6)
                ]))
              : (_openBlock(), _createElementBlock("picture", _hoisted_7, [
                  _createElementVNode("img", {
                    class: "image-wrapper__item image-wrapper__item--fit",
                    src: $setup.youtubePreview
                  }, null, 8, _hoisted_8)
                ]))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_i_play_large)
          ])
        ])
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["url"]))
}