import { SetupContext, defineComponent, ref, watch } from 'vue'
import { useToggle } from '@vueuse/core'
import { getPrefixed, getRelative, removeLanguage } from '@/utils/urls'
import urlLib from 'x-url'

export default defineComponent({
  props: {
    languages: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { slots }: SetupContext) {
    const state = ref(false)
    const toggle = useToggle(state)

    function confirmChangeLocalization(currentLang) {
      const pathname = removeLanguage(getRelative(window.location.href))
      const redirectUrl = urlLib.format({
        host:
          window.location.host,
        protocol: window.location.protocol,
        pathname: getPrefixed(
          pathname,
          window.default_language === currentLang
            ? null
            : `/${currentLang}/`
        ),
      })
      const url = urlLib.format({
        pathname: '/cookies/move/out/',
        query: {
          url: redirectUrl,
        },
      })

      window.location.href = url
    }
    return () =>
      slots.default?.({
        toggle,
        state: state.value,
        change: confirmChangeLocalization,
      })
  },
})
