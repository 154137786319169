
import { App, defineAsyncComponent } from 'vue'

const ReviewForm = defineAsyncComponent(() => import('./Form.vue'))
const Complain = defineAsyncComponent(() => import('./Complain.vue'))
const Comment = defineAsyncComponent(() => import('./Comment.vue'))
const Catalog = defineAsyncComponent(() => import('./Catalog.vue'))

import Spread from './Spread.vue'

const install = (app: App<Element>, postfix = 'Review'): void => {
  app
    .component(`${postfix}Form`, ReviewForm)
    .component(`${postfix}Complain`, Complain)
    .component(`${postfix}Comment`, Comment)
    .component(`${postfix}Spread`, Spread)
    .component(`${postfix}Catalog`, Catalog)
}

export default {
  install,
}
