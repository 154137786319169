import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ds-panel ds-panel--space_xl" }
const _hoisted_3 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_4 = { class: "search-filter-heading search-filter-heading--variant_2" }
const _hoisted_5 = { class: "g-row g-row--align_center g-row--appearance_spaced g-row--justify_between" }
const _hoisted_6 = { class: "g-cell g-cols" }
const _hoisted_7 = { class: "g-row g-row--align_center" }
const _hoisted_8 = { class: "g-cell g-cols g-cols g-cols--narrow" }
const _hoisted_9 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_10 = { class: "ds-caption ds-caption--color_dark ds-caption--size_sm ds-caption--lh_4" }
const _hoisted_11 = { class: "g-row g-row--space_lg g-row--space_xl-xl g-row--appearance_spaced" }
const _hoisted_12 = { class: "g-row g-row--align_center" }
const _hoisted_13 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_14 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_15 = { class: "ds-caption ds-caption--color_dark ds-caption--size_sm" }
const _hoisted_16 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm" }
const _hoisted_17 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_18 = { class: "g-row g-row--space_md g-row--space_lg-xl g-row--justify_end g-row--appearance_spaced" }
const _hoisted_19 = { class: "g-cell g-cols g-cols--7 g-cols--6-sm" }
const _hoisted_20 = { class: "g-row g-row--space_md g-row--space_lg-xl g-row--justify_end g-row--appearance_spaced" }
const _hoisted_21 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_22 = { class: "mo-button__icon mo-button__icon--stroke_accent" }
const _hoisted_23 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_24 = { class: "mo-button__icon mo-button__icon--left" }
const _hoisted_25 = { class: "mo-button__text is-hidden-sm" }
const _hoisted_26 = { class: "mo-button__text is-hidden-till-sm" }
const _hoisted_27 = { class: "g-cell g-cols g-cols--5 g-cols--6-sm" }
const _hoisted_28 = { class: "mo-button__icon mo-button__icon--left" }
const _hoisted_29 = { class: "mo-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_d_control_switch = _resolveComponent("d-control-switch")!
  const _component_i_rotate_ccw = _resolveComponent("i-rotate-ccw")!
  const _component_i_filter_settings = _resolveComponent("i-filter-settings")!
  const _component_i_search = _resolveComponent("i-search")!

  return ($setup.receptionFilter)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode($setup["DoctorReceptionWidget"], {
                    filter: $setup.receptionFilter,
                    modelValue: $setup.params.filters[$setup.receptionFilter.keyFilter],
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.params.filters[$setup.receptionFilter.keyFilter]) = $event))
                  }, null, 8, ["filter", "modelValue"])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["g-cell g-cols is-hidden-till-mac", { 'hidden-switch-filter-preview': $setup.switchWidgetIsHidden }])
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_d_control_switch, {
                        modelValue: $setup.params.filters[$setup.switchFilter.keyFilter],
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.params.filters[$setup.switchFilter.keyFilter]) = $event)),
                        name: $setup.switchFilter.keyFilter
                      }, null, 8, ["modelValue", "name"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("p", _hoisted_10, _toDisplayString($setup.switchFilter.title), 1)
                    ])
                  ])
                ], 2)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", {
            class: _normalizeClass(["g-cell g-cols g-cols--12 is-hidden-mac", { 'hidden-switch-filter-preview': $setup.switchWidgetIsHidden }])
          }, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_d_control_switch, {
                  modelValue: $setup.params.filters[$setup.switchFilter.keyFilter],
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.params.filters[$setup.switchFilter.keyFilter]) = $event)),
                  name: $setup.switchFilter.keyFilter
                }, null, 8, ["modelValue", "name"])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("p", _hoisted_15, _toDisplayString($setup.switchFilter.title), 1)
              ])
            ])
          ], 2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filtersList, (item) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_16, [
              (item.type === 'select')
                ? (_openBlock(), _createBlock($setup["SelectWidget"], {
                    key: 0,
                    filter: item,
                    config: item.config,
                    "reset-trigger": $setup.resetTrigger,
                    modelValue: $setup.params.filters[item.keyFilter],
                    "onUpdate:modelValue": ($event: any) => (($setup.params.filters[item.keyFilter]) = $event)
                  }, null, 8, ["filter", "config", "reset-trigger", "modelValue", "onUpdate:modelValue"]))
                : _createCommentVNode("", true),
              (item.type === 'range')
                ? (_openBlock(), _createBlock($setup["RangeWidget"], {
                    key: 1,
                    filter: item,
                    "reset-trigger": $setup.resetTrigger,
                    modelValue: $setup.params.filters[item.keyFilter],
                    "onUpdate:modelValue": ($event: any) => (($setup.params.filters[item.keyFilter]) = $event)
                  }, null, 8, ["filter", "reset-trigger", "modelValue", "onUpdate:modelValue"]))
                : _createCommentVNode("", true),
              (item.type === 'range-single')
                ? (_openBlock(), _createBlock($setup["RangeSingleWidget"], {
                    key: 2,
                    filter: item,
                    "reset-trigger": $setup.resetTrigger,
                    modelValue: $setup.params.filters[item.keyFilter],
                    "onUpdate:modelValue": ($event: any) => (($setup.params.filters[item.keyFilter]) = $event)
                  }, null, 8, ["filter", "reset-trigger", "modelValue", "onUpdate:modelValue"]))
                : _createCommentVNode("", true)
            ]))
          }), 256)),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", {
                      class: "mo-button mo-button--variant_1 mo-button--variant_11 mo-button--variant_12",
                      onClick: _withModifiers($setup.resetFilters, ["prevent"])
                    }, [
                      _createElementVNode("div", _hoisted_22, [
                        _createVNode(_component_i_rotate_ccw)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", {
                      class: "mo-button mo-button--variant_1 mo-button--variant_7 mo-button--variant_12",
                      onClick: _withModifiers($setup.toggleAdditionalFilters, ["prevent"])
                    }, [
                      _createElementVNode("div", _hoisted_24, [
                        _createVNode(_component_i_filter_settings)
                      ]),
                      _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t("Filter")), 1),
                      _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("Additional filters")), 1)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", {
                  class: "mo-button mo-button--variant_primary mo-button--variant_8 mo-button--variant_12",
                  onClick: _withModifiers($setup.goToCatalog, ["prevent"])
                }, [
                  _createElementVNode("div", _hoisted_28, [
                    _createVNode(_component_i_search)
                  ]),
                  _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t("Search")), 1)
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}