import { App, defineAsyncComponent } from 'vue'

const Gallery = defineAsyncComponent(() => import('./Gallery.vue'))
const FacilityMap = defineAsyncComponent(() => import('./Map.vue'))
const BranchCatalog = defineAsyncComponent(() => import('./BranchCatalog.vue'))
const DoctorCatalog = defineAsyncComponent(() => import('./DoctorCatalog.vue'))
const ServicesCatalog = defineAsyncComponent(() => import('./ServicesCatalog.vue'))
const Scheduler = defineAsyncComponent(() => import('./Scheduler.vue'))
const Tabs = defineAsyncComponent(() => import('./Tabs.vue'))
const Catalog = defineAsyncComponent(() => import('./Catalog.vue'))
const ReviewCatalog = defineAsyncComponent(() => import('./ReviewCatalog.vue'))

const install = (app: App<Element>, prefix = 'Facility'): void => {
  app
    .component(`${prefix}Gallery`, Gallery)
    .component(`${prefix}Map`, FacilityMap)
    .component(`${prefix}BranchCatalog`, BranchCatalog)
    .component(`${prefix}DoctorCatalog`, DoctorCatalog)
    .component(`${prefix}ServicesCatalog`, ServicesCatalog)
    .component(`${prefix}Scheduler`, Scheduler)
    .component(`${prefix}Tabs`, Tabs)
    .component(`${prefix}Catalog`, Catalog)
    .component(`${prefix}ReviewCatalog`, ReviewCatalog)
}

export default {
  install,
}
