<template>
  <render ref="content" />
</template>

<script setup>
import { ref, onMounted, useSlots } from 'vue'

const slots = useSlots()

const props = defineProps({
  active: {
    default: 0,
  },
  addHash: {
    type: Boolean,
    default: false,
  },
})

const activeItem = ref(props.active)

const preselectTabByHash = () => {
  const { hash } = window.location
  const clearHash = hash ? hash.replace('#', '') : ''

  if (clearHash) {
    activeItem.value = clearHash
  }
}

const addHashHandler = () => {
  let hash = ''

  if (activeItem.value !== props.active) {
    hash = `#${activeItem.value}`
  }

  const newPath = `${window.location.pathname}${hash}`

  window.history.replaceState(null, null, newPath)
}

const activate = item => {
  activeItem.value = item

  if (props.addHash) {
    addHashHandler()
  }
}

onMounted(() => {
  preselectTabByHash()
})

const render = () => slots.default({
  activeItem: activeItem.value,
  activate: activate,
})
</script>
