import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "multiselect__single" }
const _hoisted_4 = { class: "i-count i-count--variant_1" }
const _hoisted_5 = { class: "i-count__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_d_control_label = _resolveComponent("d-control-label")!
  const _component_d_control_multiselect = _resolveComponent("d-control-multiselect")!

  return (_openBlock(), _createElementBlock("div", {
    class: "select-wrapper",
    title: $props.filter.title.length > 18 ? $props.filter.title : ''
  }, [
    _createVNode(_component_d_control_multiselect, {
      ref: "control",
      modelValue: $setup.model,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
        $setup.debounceUpdate
      ],
      "input-class": "multiselect--variant_2",
      name: $props.filter.keyFilter,
      "track-by": $setup.filterLabel,
      "label-by": $setup.optionTitle,
      multiple: $props.config.multiple || false,
      "close-on-select": !$props.config.multiple,
      searchable: $props.config.searchable || false,
      "allow-empty": true,
      taggable: false,
      options: $props.filter.values
    }, {
      selection: _withCtx(({ values }) => [
        (Array.isArray(values) && values.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(values.map(el => el[$setup.optionTitle]).join(', ')), 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(values.length), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      label: _withCtx(() => [
        _createVNode(_component_d_control_label, {
          class: "d-control-input__label--bound",
          "input-label": $props.filter.title,
          value: $setup.model
        }, null, 8, ["input-label", "value"])
      ]),
      _: 1
    }, 8, ["modelValue", "name", "track-by", "label-by", "multiple", "close-on-select", "searchable", "options", "onUpdate:modelValue"])
  ], 8, _hoisted_1))
}