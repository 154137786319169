import { r, createSender, createUpdater, createReceiver, createFileSender, baseResource as baseSimpleResource } from '@/plugins/resource/resource'
import { queryPostfix, prefixAPI, urlConstructor } from '@/plugins/resource/api'
import { friendlyUrlGenerator } from '@/packages/vue-friendly'
import { baseResource } from '@aspectus/resource'

const c = (...args) => args.join('')

// Models
const MODEL = 'medical-facilities'
const MODEL_SERVICES = c(MODEL, '/services')
const MODEL_BRANCH = c(MODEL, '/branches')
const MODEL_BRANCH_ACTION = c(MODEL, '/branch/action')
const MODEL_BRANCH_PHOTOS = c(MODEL_BRANCH, '/photos')


// base path for the resources
const DETAIL_URL = '/detail/{id}/'
const CREATE_URL = '/create/'

export const MEDICAL_FACILITY_URL = `/${MODEL}/`
export const MEDICAL_FACILITY_CATALOG_URL = `/catalog/${MODEL}/`
export const MEDICAL_FACILITY_TABS_CLEAR_URL = `/${MODEL}/{label}/ajax/tab/`
export const MEDICAL_FACILITY_TABS_URL = `/${MODEL}/{label}/ajax/tab/{tab}/`

export const MEDICAL_FACILITY_FILTERS_URL = urlConstructor(`${MODEL}/catalog/filters/`, [])
export const MEDICAL_FACILITY_ATTRIBUTE_CONFIG_URL = prefixAPI('/catalog/attributes-config/filters/', MODEL)
export const MEDICAL_FACILITY_FILTERS_DOCTORS_URL = urlConstructor(`${MODEL}/{uuid}/filters/doctors/`, [])

export const MEDICAL_FACILITY_FILTERS_SERVICES_URL = urlConstructor(`${MODEL}/{uuid}/filters/services/`, [])
export const MEDICAL_FACILITY_FILTERS_SERVICE_URL = urlConstructor(`${MODEL}/{uuid}/filters/service/{serviceId}/`, [])
export const MEDICAL_FACILITY_FILTERS_SPECIALIZATION_URL = urlConstructor(`${MODEL}/{uuid}/filters/specialization/{spesializationId}/`, [])
export const MEDICAL_FACILITY_FILTERS_REVIEWS_URL = urlConstructor(`${MODEL}/{uuid}/filters/reviews/`, [])
export const MEDICAL_FACILITY_CATALOG_SPECIALIZATION_SERVICES_URL = `/${MODEL}/{facility}/spesializations/{spesialization}/`
export const MEDICAL_FACILITY_CATALOG_SPECIALIZATION_SERVICES_CATALOG_URL = `/${MODEL}/{uuid}/spesializations/{spesializationSlug}/`

export const MEDICAL_FACILITIES_TYPE_LIST_URL = urlConstructor(MODEL+'/type/list/', [])
export const MEDICAL_FACILITIES_DIRECTIONS_TREATMENT_LIST_URL = urlConstructor(MODEL+'/directions-treatment/list/', [])
export const MEDICAL_FACILITIES_FORM_OWNERSHIP_LIST_URL = urlConstructor(MODEL+'/form-ownership/list/', [])
export const MEDICAL_FACILITIES_PUBLIC_LIST_URL = urlConstructor(MODEL+'/public/list/', ['search'])
export const MEDICAL_FACILITIES_SEARCH_LIST_URL = urlConstructor(MODEL+'/search/', ['search'])

export const BRANCHES_BY_FACILITY_LIST_URL = urlConstructor(MODEL_BRANCH+'{/medical_id}/list/', [])

// services urls
export const MEDICAL_FACILITIES_SERVICES_LIST_URL = queryPostfix(MODEL_SERVICES+'/list/{medical_facility_id}/', ['filters'])
export const MEDICAL_FACILITIES_SERVICES_DETAIL_URL = c(MODEL_SERVICES, DETAIL_URL, )
export const MEDICAL_FACILITIES_SERVICES_CREATE_URL = c(MODEL_SERVICES, CREATE_URL, )

// docs urls
const MEDICAL_FACILITY_DOCUMENTS_CONFIG_URL = 'documents-config/medical-facility/list/'

// request moderation urls
const MEDICAL_FACILITY_MODERATION_REQUEST_URL = '/request-moderation/'

// branch photo urls
export const BRANCH_PHOTO_CREATE_URL = c(MODEL_BRANCH_PHOTOS, '/create/')
export const BRANCH_PHOTO_LIST_URL = queryPostfix(c(MODEL_BRANCH_PHOTOS, '/list/'), [])

// general resources
export const getFacility = r(DETAIL_URL, MODEL)
export const createFacility = r(CREATE_URL, MODEL, createSender)
export const updateFacility = r(DETAIL_URL, MODEL, createUpdater)
export const updateFacilityCompany = r('/update-company/{id}/', MODEL, createUpdater)

// services resources
export const facilityServicesListResource = r(MEDICAL_FACILITIES_SERVICES_LIST_URL)
export const facilityServicesFiltersListResource = r('/filter/list{/medical_facility_id}/', MODEL_SERVICES)
export const facilityServicesListByBranchResource = r(queryPostfix(MODEL_SERVICES+'/list-by-branch{/branch_id}/', ['filters']))
export const facilityServicesRetrieveResource = r(MEDICAL_FACILITIES_SERVICES_DETAIL_URL)
export const facilityServicesUpdateResource = r(MEDICAL_FACILITIES_SERVICES_DETAIL_URL, '', createUpdater)
export const facilityServicesCreateResource = r(MEDICAL_FACILITIES_SERVICES_CREATE_URL, '', createSender)

// branches resources
export const ownListBranchesResource = r(queryPostfix('/owner/list/', ['filters']), MODEL_BRANCH)
export const listBranchesResource = r(queryPostfix('{/medical_id}/list/', ['filters']), MODEL_BRANCH)
export const filtersListBranchesResource = r('/filter/list/', MODEL_BRANCH)
export const createBranchResource = r(CREATE_URL, MODEL_BRANCH, createSender)
export const getBranchResource = r(DETAIL_URL, MODEL_BRANCH,)
export const updateBranchResource = r(DETAIL_URL, MODEL_BRANCH, createUpdater)
export const createBranchPhotoResource = r(CREATE_URL, MODEL_BRANCH_PHOTOS, createFileSender)
export const updateBranchPhotoResource = r('/update/', MODEL_BRANCH_PHOTOS, createUpdater)
export const listBranchPhotoResource = r(BRANCH_PHOTO_LIST_URL)
export const filtersListServicesBranchesResource = r('/filter/list-by-branch{/branch_id}/', MODEL_SERVICES)
export const listServicesBranchesResource = r(queryPostfix('/services/list{/branch_id}/'), MODEL_BRANCH)
export const updateServiceBranchesResource = r(queryPostfix('/services/update/{branch_id}/'), MODEL_BRANCH, createUpdater)
export const facilityBranchAcceptResource = r('/accepted/', MODEL_BRANCH_ACTION, createSender)
export const facilityBranchRejectResource = r('/rejected/', MODEL_BRANCH_ACTION, createSender)
export const facilityBranchDiscontinuedResource = r('/stopped/', MODEL_BRANCH_ACTION, createSender)

// filter resources
export const medicalFacilityResource = friendlyUrlGenerator([
  MEDICAL_FACILITY_CATALOG_URL, 'ajax'
], baseResource)
export const medicalFacilityBranchesResource = friendlyUrlGenerator([
  MEDICAL_FACILITY_URL, 'ajax/branches'
], baseResource)
export const medicalFacilityDoctorsResource = friendlyUrlGenerator([
  MEDICAL_FACILITY_URL, 'ajax/doctors'
], baseResource)
export const medicalFacilityServicesResource = friendlyUrlGenerator([
  MEDICAL_FACILITY_URL, 'ajax/services'
], baseResource)
export const medicalFacilityReviewsResource = friendlyUrlGenerator([
  MEDICAL_FACILITY_URL, 'ajax/reviews'
], baseResource)
export const medicalFacilitySpecializationServicesCatalogResource = friendlyUrlGenerator([
  MEDICAL_FACILITY_CATALOG_SPECIALIZATION_SERVICES_CATALOG_URL, 'ajax'
], baseResource)
export const medicalFacilitySpecializationServicesResource = friendlyUrlGenerator([
  MEDICAL_FACILITY_CATALOG_SPECIALIZATION_SERVICES_URL, 'ajax'
], baseResource)

export const medicalFacilityAttributeConfigResource = friendlyUrlGenerator([
  MEDICAL_FACILITY_ATTRIBUTE_CONFIG_URL, ''
], baseSimpleResource)

export const medicalFacilityFiltersResource = createReceiver(MEDICAL_FACILITY_FILTERS_URL)
// export const medicalFacilityTabsClearResource = createReceiver(MEDICAL_FACILITY_TABS_CLEAR_URL)
// export const medicalFacilityTabsResource = createReceiver(MEDICAL_FACILITY_TABS_URL)
export const medicalFacilityFiltersDoctorsResource = createReceiver(MEDICAL_FACILITY_FILTERS_DOCTORS_URL)
export const medicalFacilityFiltersServicesResource = createReceiver(MEDICAL_FACILITY_FILTERS_SERVICES_URL)
export const medicalFacilityFiltersServiceResource = createReceiver(MEDICAL_FACILITY_FILTERS_SERVICE_URL)
export const medicalFacilityFiltersSpecializationResource = createReceiver(MEDICAL_FACILITY_FILTERS_SPECIALIZATION_URL)
export const medicalFacilityFiltersReviewsResource = createReceiver(MEDICAL_FACILITY_FILTERS_REVIEWS_URL)

// documents resources
export const facilityDocumentsListResource = r(MEDICAL_FACILITY_DOCUMENTS_CONFIG_URL)
export const facilityModerationRequestResource = r(MEDICAL_FACILITY_MODERATION_REQUEST_URL, MODEL, createSender)