import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "review-spread" }
const _hoisted_2 = { class: "review-spread__item" }
const _hoisted_3 = { class: "review-spread__point" }
const _hoisted_4 = { class: "review-spread__line-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([5, 4, 3, 2, 1], (item) => {
      return _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(item), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "review-spread__progress",
            style: _normalizeStyle('width:' + $setup.totalSpread[item] + '%')
          }, null, 4)
        ])
      ])
    }), 64))
  ]))
}