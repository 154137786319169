
import { App } from 'vue'

import UiTrigger from './Trigger.vue'

const install = (app: App<Element>, prefix = 'Search'): void => {
  app
    .component(`${prefix}Trigger`, UiTrigger)
}

export default {
  install,
}
