export const getConfig = () => {
  console.log(process.env, process.env.FIREBASE_CREDENTIAL);
  return {
    "apiKey": "AIzaSyBFl_hYQA_8w31tVmdk6vNQ9GnsSxzJx50",
    "projectId": "medonline-test",
    "storageBucket": "medonline-test.appspot.com",
    "messagingSenderId": "546853290413",
    "appId": "1:546853290413:web:9fb2cb4c09edffa04c6124",
    authDomain: window.location.host,
  }
}
